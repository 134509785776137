import React from 'react'

export default class About extends React.Component {
    render() {
        return (
            <div className="App">
                <div className={"container"}>
                    <div className="pb-1 border-bottom my-5">
                        <h1>About Us</h1>
                    </div>
                </div>

                <div className={"container"}>
                    {/* origin */}
                    <p>Gooseworks is an IT startup currently based out of Rocky Ford, Colorado,
                        focused on bringing infrastructure and peace of mind to businesses of all sizes.</p>
                </div>
                <div className={"container"}>
                    {/* mission statement */}
                    <p>We are dedicated to providing our customers quality service and reliable infrastructure at a reasonable rate.</p>
                </div>
                <div className={"container"}>
                    <div className={"container pb-1 border-bottom my-5"}>
                        <h2>Sponsorships</h2>
                    </div>
                    <div className={"container"}>
                        <a href="https://www.debian.org" target="_blank">
                            <img src={"https://www.debian.org/logos/openlogo-nd-75.png"}/>
                        </a>
                    </div>
                </div>
                <div className={"container"}>
                    {/* customer specialization */}
                </div>
                {/*
                <div className="container"> quotes/testimonial
                    <h2 className="pb-1 border-bottom">Testimonials</h2>
                    <div class={"card"}>
                        <div class="card-body">
                            <p>Gooseworks is gr8</p>
                            <h6>Jonathan Harlfinger, Harlfinger Networks</h6>
                        </div>
                    </div>
                </div>
                */}
                <div className={"container"}>
                    {/* call to action */}
                </div>
            </div>
        );
    }
}