import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import {Container, Nav} from "react-bootstrap";

export default class BootstrapNavbar extends React.Component {
    render() {
        return(
            <>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Container>
                        <Navbar.Brand href="/">Gooseworks</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link href='/'>Home</Nav.Link>
                                <Nav.Link href='/services'>Services</Nav.Link>
                                <Nav.Link target="_blank" href="https://uptime.gooseworks.net/status/main">Status</Nav.Link>
                                <Nav.Link href='/about'>About Us</Nav.Link>
                                {/*<Nav.Link href="/client-portal">Client Portal</Nav.Link>*/}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>
        )
    }
}