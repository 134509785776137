import React from 'react';
import card from './snippets/card.js';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";


export default class Services extends React.Component {
    render() {
        return (
            <div className="App">
                <div className="container">
                    <div className="pb-1 border-bottom my-5">
                        <h1>Services</h1>
                    </div>
                    <div className="container">
                        <div className={"row"}>
                            <div className={"col mb-2"}>
                                <h2 className={"p-2 border-bottom"}>Backup Solutions</h2>
                                <p className={"p-2"}>In today's business world, safeguarding your valuable data is
                                    critical.
                                    Our backup solutions ensure that your information is always protected and easily
                                    recoverable,
                                    no matter what challenges come your way. Benefit from local expertise and
                                    personalized support designed to keep your data safe and sound.</p>
                            </div>
                            <div className={"col mb-2"}>
                                <h2 className={"p-2 border-bottom"}>Managed IT</h2>
                                <p className={"p-2"}>From the heart of southeast Colorado, we offer managed
                                    infrastructure solutions that keep your systems running smoothly and securely,
                                    so you can focus on growing your business. Experience the peace of mind that comes
                                    with local, personalized service and dependable technology. When you call our
                                    support line,
                                    you're ringing a real American on the other end of the phone.
                                </p>
                            </div>
                            <div className={"col mb-2"}>
                                <h2 className={"p-2 border-bottom"}>Hosted Services</h2>
                                <p className={"p-2"}>Experience the convenience and reliability of having your
                                    applications
                                    managed from our local low-latency datacenter. We provide secure, high-performance
                                    hosting solutions
                                    tailored to your needs, so you can focus on growing your business while we handle
                                    the tech.</p>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col mb-2"}>
                                <h2 className={"p-2 border-bottom"}>Colocation</h2>
                                <p className={"p-2"}>Coming soon to Rocky Ford, CO</p>
                            </div>
                            <div className={"col mb-2"}>
                                <h2 className={"p-2 border-bottom"}>Consulting Services</h2>
                                <p className={"p-2"}>Need something not listed here? Let's talk.</p>
                            </div>
                        </div>

                        <div className={"container"}>
                            <p><a href={"mailto:cfarnworth@gooseworks.net"}><FontAwesomeIcon icon={faEnvelope}
                                                                                             size={"5x"}/></a>
                            </p>
                            {/* contact us */}


                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
